import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Fade } from "react-reveal";
import video from "../../assets/deer.mp4";
import video2 from "../../assets/robin.mp4";
import AboutHeroVideo from "../../components/AboutHeroVideo/AboutHeroVideo";
import Navigation from "../../components/Nav/Navigation";
import { Col, Container, Row } from "reactstrap";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import styles from './About.module.scss';
import { Parallax } from "react-parallax";
import bannerImg from "../../assets/treebark.webp";
import CountUp from "react-countup";
import { InView } from 'react-intersection-observer';
import { FaFacebookF, FaInstagram, FaLinkedin, FaPinterestP } from "react-icons/fa";
import { IconContext } from "react-icons";
import Footer from "../../components/Footer/Footer";
import MeetTheTeam from "../../components/MeetTheTeam/MeetTheTeam";
import { BasemapLayer } from "react-esri-leaflet";
import { MapContainer, Marker } from "react-leaflet";
import L from 'leaflet';
import { getAboutPageContent } from '../../graphql/resolvers/aboutPage';
import axios from 'axios';
import { contentfulKey, graphQLEndPoint } from '../../graphql/config/config';

L.Icon.Default.imagePath = '/assets/images/';

const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
};

const About = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [content, setContent] = useState<any>(); 

    useEffect(() => {
        axios.post(
            graphQLEndPoint,
            {
                query: getAboutPageContent,               
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: contentfulKey,
                }
            }).then(response => {                
                setContent(response.data.data.aboutPageContentCollection.items[0]);
            })
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 1500);
                
        return () => clearTimeout(timer);

    }, []);


    return (
        <>
            <Helmet>
                <title>{'About Us & Meet The Team | TreeDwellers'}</title>
                <meta name="description" content={'About Treedwellers and our Team. Luxury tree houses at Cornbury, Oxfordshire.'} />
                <link rel='canonical' href='https://treedwellers.com/about' />
                <link
                    rel="stylesheet"
                    href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
                    integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
                    crossOrigin=""
                />
            </Helmet>

            <Navigation />
            <AboutHeroVideo />

            <section className={`${styles.AboutSection} section-ash`}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col lg={6} className={`text-center ${styles.textContainer}`}>
                            <Fade left>
                                <h3 className="mt-3 display-3">{content?.sectionOneTitle}</h3>
                                <Slide {...properties}>
                                    <div className={styles.Quote}>
                                        <h4>
                                            <em>"</em>{content?.quoteOne}<em>"</em>
                                        </h4>
                                        <p>{content?.quoteOneParagraph}</p>
                                    </div>
                                    <div className={styles.Quote}>
                                        <h4>
                                            <em>"</em>{content?.quoteTwo}<em>"</em>
                                        </h4>
                                        <p>{content?.quoteTwoParagraph}</p>
                                    </div>
                                </Slide>
                            </Fade>
                        </Col>
                        <Col lg={6} className={`p-0 ${styles.videoContainer}`}>
                            <Fade right>
                                <video loop autoPlay muted playsInline className={`${styles.video} ${styles.videoRight}`}>
                                    <source src={video} type="video/mp4" />
                                </video>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="clearfix" />

            <Parallax
                blur={{ min: -15, max: 2 }}
                bgImage={bannerImg}
                strength={300}
                className="banner thin"
                contentClassName="container"
                bgClassName="bg"
            >
                <Row className="text-center">
                    <Col md={6} lg={3}>
                        <Fade bottom>
                            <div className={styles.FactContainer}>
                                <InView>
                                    {({ inView, ref }) => (
                                        <div className={styles.Fact} ref={ref}>
                                            {inView && (
                                                <h2><CountUp end={1700} separator="," duration={6} />+</h2>
                                            )}
                                            <div className={styles.FactTitle}>Acres of Land</div>
                                            <p>
                                                Set in Cornbury Park, with over 1,700 acres of ancient forests found in Britain. Idyllic, uninhibited and protected grounds.
                                            </p>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6} lg={3}>
                        <Fade bottom>
                            <div className={styles.FactContainer}>
                                <InView>
                                    {({ inView, ref }) => (
                                        <div className={styles.Fact} ref={ref}>
                                            {inView && (
                                                <h2><CountUp end={1000} separator="," duration={6} />+</h2>
                                            )}
                                            <div className={styles.FactTitle}>Years old &amp; Thriving</div>
                                            <p>
                                                For a millennia our grounds have been a sanctuary for wildlife, and now you can join this Eden-esque playground.
                                            </p>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6} lg={3}>
                        <Fade bottom>
                            <div className={styles.FactContainer}>
                                <InView>
                                    {({ inView, ref }) => (
                                        <div className={styles.Fact} ref={ref}>
                                            {inView && (
                                                <h2><CountUp end={10} duration={3} />+</h2>
                                            )}
                                            <div className={styles.FactTitle}>Years In The Making</div>
                                            <p>
                                                Over a decade studying, travelling and crafting. Making these the most extraordinary treehouses you've ever seen.
                                            </p>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6} lg={3}>
                        <Fade bottom>
                            <div className={styles.FactContainer}>
                                <InView>
                                    {({ inView, ref }) => (
                                        <div className={styles.Fact} ref={ref}>
                                            {inView && (
                                                <h2><CountUp end={3} duration={1.5} /></h2>
                                            )}
                                            <div className={styles.FactTitle}>Meters off the ground</div>
                                            <p>
                                                Witness the majesty of five species of free-roaming deer, see fish swim the deep lakes. and hear the unadulterated birdsongs.
                                            </p>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </Parallax>

            <section className={`${styles.AboutSection} section-clay`}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6} className={`text-center ${styles.textContainer}`}>
                            <Fade left>
                                <h3 className="mt-3 display-4">{content?.sectionTwoTitle}</h3>
                                <Slide {...properties}>
                                    <div className={styles.Quote}>
                                        <h4>
                                            <em>"</em>{content?.quoteThree}<em>"</em>
                                        </h4>
                                        <p>{content?.quoteThreeParagraph}</p>
                                    </div>
                                    <div className={styles.Quote}>
                                        <h4>
                                            <em>"</em>{content?.quoteFour}<em>"</em>
                                        </h4>
                                        <p>{content?.quoteFourParagraph}</p>
                                    </div>
                                </Slide>
                            </Fade>
                        </Col>
                        <Col md={6} className={`p-0 ${styles.videoContainer}`}>
                            <Fade left>
                                <video loop autoPlay muted playsInline className={`${styles.video} ${styles.videoRight}`}>
                                    <source src={video2} type="video/mp4" />
                                </video>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>

            <MeetTheTeam />

            <Container>
                <Row>
                    <h1 className="text-center">Where To Find Us</h1>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col className="text-center">
                        <p><strong>Treedwellers Cornbury</strong> Witney Road, Chipping Norton, Oxfordshire, OX7 3DF</p>
                        <p><strong>From Heathrow</strong> in 1 hour, 15 mins</p>
                        <p><strong>From London or Gatwick</strong> in 2 hours</p>
                        <p><strong>Need a driver?</strong> Say the word and we'll source a trusted driver who'll get you here quickly and hassle-free.</p>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <MapContainer
                        center={[51.84596664740785, -1.4909092751464859]}
                        zoom={14}
                        scrollWheelZoom={false}
                        style={{ height: '600px', minHeight: '50vh', width: '100%' }}
                        id="map"
                    >
                        <BasemapLayer name="Imagery" />
                        <BasemapLayer name="ImageryLabels" />
                        <Marker position={[51.84596664740785, -1.4909092751464859]} />
                    </MapContainer>
                </Row>
            </Container>

            <Footer />
        </>
    );
};

export default About;