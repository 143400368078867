import React from 'react';
import styles from './Nav.module.scss';
import TDLogo from "../../assets/td_logo_full_white.svg";
import { Button } from 'reactstrap';
import { Fade } from "react-reveal";
import { Link, To } from "react-router-dom";
import Banner from '../Banner/Banner';

class Navigation extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        const showBookbtn = (typeof this.props.book === 'undefined') ? true : this.props.book;
        const showLogo = (typeof this.props.showLogo === 'undefined') ? true : this.props.showLogo;

        const links = [

            {to: '/', label: 'Home'},
            {to: '/treehouses', label: 'Treehouses'},
            {to: '/about', label: 'About'},
            {to: '/blog', label: 'Blog'},
            // {to: '/events', label: 'Events'},
            {to: '/weddings', label: 'Weddings'},
            {to: '/jobs', label: 'Jobs'},
            {to: '/giftcards', label: 'Giftcards'}, 
            {to: '/contact', label: 'Contact'},
            { to: '/faq', label: 'FAQ' },

        ];

        this.state = {
            showBookbtn: showBookbtn,
            show: false,
            menuOpen: false,
            navClass: false,
            showLogo: showLogo,
            links: links
        };


    }

    async componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        this.trackScrolling();
        setTimeout(() => this.setState({
            show: true,
        }), 1500)

    }

    toggleNav = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }


    trackScrolling = () => {
        if (window.scrollY > 150) {
            this.setState({
                navClass: true,
                showLogo: true
            })
        } else {
            this.setState({
                navClass: false,
                showLogo: (typeof this.props.showLogo === 'undefined') ? true : this.props.showLogo
            })
        }
    };

    render() {
        return (
            <>
                <header>
                    <Fade top when={this.state.show}>
                        <div className={styles.tdNav + (this.state.navClass ? ' ' + styles.fixed : '')}>
                            {this.state.showBookbtn &&
                                <Link to={'/treehouses'} title={'Treehouse Holidays In The Cotswalds'}>
                                    <Button outline
                                        color="white"
                                        className={styles.btnBook}
                                        size={"lg"}>Book Your Escape Today 
                                        </Button>
                                </Link>
                            }


                            {this.state.showLogo &&
                                <Link to={'/'}>
                                    <img src={TDLogo} alt="Treedwellers" className={styles.logo} height={'166px'}
                                        width={'249px'} />
                                </Link>
                            }
                        </div>

                    </Fade>
                </header>

                <Fade top when={this.state.show}>
                    <div className={styles.Hamburger + ' ' + (this.state.menuOpen ? styles.open : 'closed')}
                        onClick={this.toggleNav}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                </Fade>
                <menu
                    className={styles.MenuOverlay + ' ' + (this.state.menuOpen ? styles.menuOpen : styles.menuClosed)}>
                    <nav>
                        <ul>
                            {this.state.links.map((item: { to: To; label: string; }) => {
                                return (
                                    <li style={{ height: `calc(100% / ${this.state.links.length})` }}>
                                        <Link to={item.to} onClick={this.toggleNav}>{item.label}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                </menu>
            </>

        );
    }
}

export default Navigation;
