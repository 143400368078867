import React from 'react';
import styles from './Footer.module.scss';
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Logo from '../../assets/td_logo_full_white.svg';
import {BsArrowUp} from "react-icons/bs";
import {Fade} from "react-reveal";
import {Link} from "react-router-dom";
import {IconContext} from "react-icons";
import {FaFacebookF, FaInstagram, FaLinkedin} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const initialState = {
    modal: false
}


    class Footer extends React.Component<any, any>
    {
        constructor(props: any) {
            super(props);
            this.state = initialState;
        }

        resetStates = () => {
            this.setState(initialState)
        }

        toggleModel = () => {
            this.setState({
                modal: !this.state.modal
            })
        }

        componentDidMount() {

        }

        componentDidUpdate() {

        }

        render()
        {
            return(
                <>

                    <section className={styles.SocialSection+' section-grey'}>

                        <Fade bottom>
                            <h5 className={'text-center'}>Ready to Connect?</h5>
                        </Fade>
                        <Fade bottom>
                            <h4 className={'text-center'}>Socials</h4>
                        </Fade>
                        <IconContext.Provider value={{ size:'4rem',className:'socials'}}>
                            <div className={styles.SocialIcons+' text-center'}>
                                <Fade bottom>
                                    <a href={'//www.instagram.com/iamtreedwellers/'} target={'_blank'} rel={'noreferrer'}>
                                        <div>
                                            <FaInstagram/>
                                        </div>
                                        <div>
                                            Instagram
                                        </div>
                                    </a>
                                    <a href={'//fb.com/iamtreedwellers'} target={'_blank'} rel={'noreferrer'}>
                                        <div>
                                            <FaFacebookF/>
                                        </div>
                                        <div>

                                            Facebook
                                        </div>
                                    </a>                                   
                                    <a href={'//www.linkedin.com/company/iamtreedwellers/'} target={'_blank'} rel={'noreferrer'}>
                                        <div>
                                            <FaLinkedin/>
                                        </div>
                                        <div>

                                            LinkedIn
                                        </div>
                                    </a>
                                </Fade>
                            </div>
                        </IconContext.Provider>
                    </section>

                    <section className={styles.Footer}>
                        <Container>
                            <Fade bottom>
                                <Row className={styles.FooterRow}>
                                    <Col md={4}>
                                        <h4 className={'mb-3'}>Have any questions?</h4>
                                        <Link to={'/contact'}>
                                            <Button outline color="white" size={"lg"}>Get in touch</Button>
                                        </Link>
                                    </Col>
                                    <Col md={4}>
                                        <div onClick={() => {window.scrollTo(0, 0);}} style={{cursor:'pointer'}}>
                                            <div><BsArrowUp size={'4rem'} className={'mb-2'}/></div>
                                            <div>BACK TO TOP</div>
                                        </div>

                                    </Col>
                                    <Col md={4}>
                                        <img src={Logo} alt={'TreeDwellers'} height={'110px'} width={'220px'}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr style={{height: '1px',
                                            color: '#fff',
                                            opacity: 1}} className={'mt-5 mb-5'}/>
                                    </Col>
                                </Row>
                                <Row className={styles.FooterRow}>
                                    <Col md={4}>
                                        &copy; Copyright TreeDwellers
                                    </Col>
                                    <Col md={4}>
                                        {(new Date().getFullYear())}. All rights reserved.
                                    </Col>
                                    <Col md={4}>
                                        <Link to={'/terms-and-conditions'}>T&C's</Link> | <Link to={'/privacy-policy'}>Privacy Policy</Link>
                                    </Col>
                                </Row>
                            </Fade>
                        </Container>
                    </section>



                            <Modal isOpen={this.state.modal} toggle={this.toggleModel} centered scrollable>
                                <ModalHeader toggle={this.toggleModel}>Contact Us</ModalHeader>
                                <ModalBody>
                                    <FormGroup floating>
                                        <Input
                                            id="name"
                                            name="name"
                                            placeholder="Your name"
                                            type="text"
                                        />
                                        <Label for="name">
                                            Your name
                                        </Label>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            type="email"
                                        />
                                        <Label for="email">
                                            Your Email
                                        </Label>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input
                                            id="message"
                                            name="message"
                                            placeholder="message"
                                            type="textarea"
                                            style={{height:'150px'}}
                                        />
                                        <Label for="exampleEmail">
                                            Your Message
                                        </Label>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>

                                    <ButtonGroup style={{width:'100%'}}>
                                        <Button color={'secondary'} onClick={this.toggleModel}>
                                            Send Message
                                        </Button>
                                        <Button color={'danger'} onClick={this.toggleModel}>
                                            Cancel
                                        </Button>
                                    </ButtonGroup>
                                </ModalFooter>
                            </Modal>

                </>
                )

        }

    }

export default Footer;
