import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';


/** GRAPHQL with Apollo **/
/*import {ApolloProvider, ApolloClient, InMemoryCache} from "@apollo/client";

const graphQLClient = new ApolloClient({
    uri: 'https://graphql.contentful.com/content/v1/spaces/8k7hdgd4hd8z/',
    cache: new InMemoryCache()
})*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  //  <ApolloProvider client={graphQLClient}>

    <BrowserRouter>
        <App />
    </BrowserRouter>
    //</ApolloProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
