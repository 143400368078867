import React from 'react';
import styles from './Preloader.module.scss';
import TDLogo from "../../assets/td_logo_full_white.svg";
import background from "../../assets/TreeDwellers_Silhouettes-intro.webp";

class Preloader extends React.Component<any, any> {
    constructor(props: any)
    {
        super(props);
        this.state = {
            loaded: false,
            loadedClass: ''
        };

    }

    handleOverflow = () => {
/*        if(this.state.loaded) {
            document.body.style.overflowY = "scroll"
        } else {
            document.body.style.overflowY = "hidden"
        }*/
    }

    componentDidMount() {
        this.setState({
            loaded:this.props.loaded,
            loadedClass: (this.props.loaded ? styles.loaded : '')
        }, this.handleOverflow)
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ) {
        if (prevProps.loaded !== this.props.loaded) {
            this.setState({
                loaded: this.props.loaded,
                loadedClass: (this.props.loaded ? styles.loaded : '')
            }, this.handleOverflow);
        }
    }

    render() {
        return(
            <div className={styles.Preloader + ' ' + this.state.loadedClass} style={{backgroundImage: `url(${background})`}}>
                <div className={styles["Preloader-logo"]}>
                    <div className={styles["Preloader-logo__icon"]}>
                        <img src={TDLogo} alt="Treedwellers" className={styles.logo} height={'166px'} width={'249px'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preloader;
