import React from 'react';
import styles from './MeetTheTeam.module.scss';
import MeetTheTeamCard from "../MeetTheTeamCard/MeetTheTeamCard";
import axios from "axios";
import {contentfulKey, graphQLEndPoint} from "../../graphql/config/config";
import {getPageBySlug} from "../../graphql/resolvers/page";
import {Container, Row} from "reactstrap";

const initialState = {
    loaded:false,
    showTeam:true,
    showMember:false,
    teamMember:[]
}



class MeetTheTeam extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    getContent = () => {
        axios.post(
            graphQLEndPoint,
            {
                query: getPageBySlug,                variables: {
                    "slug": 'about'
                }
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: contentfulKey,
                }
            }).then(response => {
            this.setState({
                pageData: response.data.data.pageCollection.items[0],
                theTeam: response.data.data.pageCollection.items[0].theTeamCollection.items,
                loaded:true
            })
        })
    }

    async componentDidMount() {
        this.getContent()
    }


    moreInfo = (teamMember: any) => {
        console.log(teamMember)
        this.setState({
            showTeam:false,
            // showTeamMember: true,
            teamMember: teamMember
        }, () =>
        setTimeout(
            () => {
                this.setState({
                    showTeamMember: true
                })
                console.log('done')
            }
        , 500))
    }

    render() {

        return(
            <>
                <section className={styles.MeetTheTeam} data-testid="MeetTheTeam">

                    <Container fluid>
                        <Row>
                            <h1 className={'text-center'}>Meet Our Team</h1>
                            <p className={'text-center'}>
                                TreeDwellers wouldn't be possible without...
                            </p>
                        </Row>

                                {this.state.showTeam && (
                                    <Row>
                                        {this.state.loaded && (
                                            this.state.theTeam.map((teamMember: any, index: React.Key | null | undefined) => (

                                                <MeetTheTeamCard teamMember={teamMember} key={index} moreInfo={this.moreInfo} />

                                            ))
                                        )}
                                    </Row>
                                )}
                    </Container>




                </section>
            </>
        )

    }
}

export default MeetTheTeam;
