import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Navigation from "../../components/Nav/Navigation";
import Preloader from "../../components/Preloader/Preloader";
import Footer from "../../components/Footer/Footer";
import axios from 'axios';
import { contentfulKey, graphQLEndPoint } from '../../graphql/config/config';
import { getFaqPageContent } from '../../graphql/resolvers/faqPage';
import Faq from '../../components/Faq/Faq';
import PageHero from '../../components/PageHero/PageHero';
import hero from '../../assets/office-sculpture.jpg';

interface FaqProps {
    // Add any props here if needed
}

const FaqPage: React.FC<FaqProps> = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);    

    const loading = () => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 1500);
    };

    useEffect(() => {
        loading();
    }, []); // 

    return (
        <>
            <Helmet>
                <title>{' '}</title>
                <meta name="description" content={' '} />
            </Helmet>
            <Preloader loaded={isLoaded} />
            <Navigation />
            <PageHero hero={hero} heading={'FAQ'} />
            <div>
                <Faq />
            </div>
            <Footer />
        </>
    );
};

export default FaqPage;