export const getFaqPageContent = `query getFaqContent {
    faqCollection {
        items {
            pageTitle
            pageDescription {
                json                
            }
            petsQuestion
            petsAnswer
            kitchenQuestion             
            kitchenAnswer {
                json
            }
            snacksQuestion
            snacksAnswer
            bathroomQuestion
            bathroomAnswer
            whatsIncluded
            whatsIncludedAnswer
            notIncludedQuestion
            notIncludedAnswer
            heatingQuestion
            heatingAnswer
            smoking
            smokingAnswer
            location
            locationAnswer {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
            }
            locationAnswerSubtext
            parkingQuestion
            parkingAnswer
            checkInQuestion
            checkInAnswer
            oneNightQuestion
            oneNightAnswer
        }
    }
}`

