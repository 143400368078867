export const getAboutPageContent = `query getAboutPageContent {
    aboutPageContentCollection {
        items {
            sectionOneTitle
            quoteOne
            quoteOneParagraph
            quoteTwo
            quoteTwoParagraph
            sectionTwoTitle
            quoteThree
            quoteThreeParagraph
            quoteFour
            quoteFourParagraph
        }
    }
}`