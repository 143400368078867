import React from 'react';
import styles from './MeetTheTeamCard.module.scss';
import {Col, Fade} from "reactstrap";

import {FaLinkedin} from "react-icons/fa";
import {IconContext} from 'react-icons';


const initialState = {

}



class MeetTheTeamCard extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    async componentDidMount() {
        this.setState({
            teamMember: this.props.teamMember
        })
    }

    render() {

        return(
            <>
                <Col md={6}>
                    <Fade bottom>
                    <div className={styles.MeetTheTeamCard} data-testid="MeetTheTeamCard">
                        <div className={styles.Content}>
                            <div className={styles.Front}>
                                <div className={styles.Img}>
                                    <img src={this.props.teamMember.photoFun.url} className={styles.ImgHover} alt='Team member photo fun'/>
                                    <img src={this.props.teamMember.photo.url} className={styles.ImgMain}  alt='Team member photo'/>
                                </div>
                                {/*<div className={styles.Img}  style={{ backgroundImage: `url(${this.props.teamMember.photoFun.url})` }}>

                                </div>*/}
                                <div className={styles.Bio}>
                                    <div className={styles.Name}>
                                        {this.props.teamMember.name}
                                    </div>
                                    <div className={styles.JobTitle}>
                                        {this.props.teamMember.jobTitle}
                                    </div>
                                    <div className={styles.BioText} dangerouslySetInnerHTML={{ __html: this.props.teamMember.bio.replace(/\n/g, "<br />")}}>

                                    </div>
                                    <div className={styles.Socials}>
                                        <IconContext.Provider value={{ size:'2rem',className:'socials'}}>
                                            {this.props.teamMember.linkedIn && (
                                                <a href={this.props.teamMember.linkedIn} target={'_blank'} rel={'noreferrer'}>
                                                    <div>
                                                        <FaLinkedin/>
                                                    </div>
                                                </a>
                                            )}
                                        </IconContext.Provider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </Col>
            </>
        )

    }
}

export default MeetTheTeamCard;
