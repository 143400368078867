import React from 'react';
import styles from './PageHero.module.scss';

import {Fade} from "react-reveal";
import {Parallax} from "react-parallax";



class PageHero extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);
        this.state = {
            hero: this.props.hero,
            heading: this.props.heading,
            fade: false,
            fadeDuration: (typeof this.props.fadeDuration === 'undefined') ? '1500' : this.props.fadeDuration
        };
    }

    scroll = () => {
        window.scrollTo(0, window.innerHeight);
    }

    async componentDidMount() {
        setTimeout(() => this.setState({
            fade: true,
        }), this.state.fadeDuration)
    }

    render() {

        return(
            <>
                <Parallax
                    blur={{min: -15, max: 2}}
                    bgImage={this.state.hero}
                    strength={300}
                    className={styles.PageHero}
                    contentClassName={styles.content}
                    bgClassName={'bg'}
                >

                    <div className={styles.contentMain}>
                        <div className={styles.headline}>
                            <h1 className="pt-2">
                                <Fade bottom when={this.state.fade}>
                                    {this.state.heading}
                                </Fade>

                            </h1>
                            <Fade bottom when={this.state.fade}>
                                <div className={styles.contentAction} onClick={this.scroll}>
                                    <div className={styles.go}>
                                        <button className={styles.scroll}><span/></button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </Parallax>
            </>
        )

    }
}

export default PageHero;
