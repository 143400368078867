import React from 'react';
import {Helmet} from "react-helmet";
import Navigation from "../../components/Nav/Navigation";
import Preloader from "../../components/Preloader/Preloader";
import Footer from "../../components/Footer/Footer";
import EmailSignupForm from '../../components/EmailSignupForm/EmailSignupForm';
import PageHero from '../../components/PageHero/PageHero';

import hero from '../../assets/laptop-forest.jpg';
import {Container, Row, Col} from 'reactstrap'; 

const initialState = {

}



class EmailSignup extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    loading = () => {
        setTimeout(() => this.setState({
            isLoaded: true,
        }), 1500)
    }

    async componentDidMount() {
        this.loading();
    }

    render() {

        return(
            <>
                <Helmet>
                    <title>{' '}</title>
                    <meta name="description" content={' '}/>
                    <link rel='canonical' href='https://treedwellers.com/sign-up' />
                </Helmet>
                <Preloader loaded={this.state.isLoaded}/>
                <Navigation/>
                <PageHero hero={hero} heading={'Sign Up to our mailing list'}/>
                <Container fluid>
                    <Row>
                        <Col md>
                            <EmailSignupForm />
                        </Col>
                    </Row>
                </Container>
              
                <Footer/>
            </>
        )

    }
}

export default EmailSignup;
