import React from 'react';
import styles from './EmailSignupForm.module.scss';
import Mailchimp from 'react-mailchimp-form'; 
import { FormGroup, Col, Row, Container } from 'reactstrap';


const initialState = {

}



class EmailSignupForm extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    async componentDidMount() {

    }

    render() {

        return(
            <>
                <div className={styles.EmailSignupForm} data-testid="EmailSignupForm">
                    <Container md className={styles.bg}> 
                        <Row>
                            <Col md>
                                <FormGroup row className={styles.formGroup}> 
                                    <h2>Get 15% off when you sign up!</h2>
                                    <p>Stay up to date with all things TreeDwellers. We'll send you news, offers and updates. Don't worry we won't spam you and you can unsubscribe at any time.</p>
                                    <Mailchimp
                                        action="https://treedwellers.us14.list-manage.com/subscribe/post?u=d2b5290ae637594df824af2f1&amp;id=9bc500938d&amp;f_id=0028fde0f0"
                                        fields={[
                                        { 
                                                name: "EMAIL",
                                                placeholder: "Email",
                                                type: "email",
                                                required: true,
                                            },
                                            {
                                                name: "FNAME",
                                                placeholder: "Name",
                                                type: "text",
                                                required: true,
                                            },
                                            {
                                                name: "LNAME",
                                                placeholder: "Last Name",
                                                type: "text",
                                                required: true,
                                            },
                                            {
                                                name: "BIRTHDAY[month]",
                                                placeholder: "Birth Month (MM)",
                                                type: "text",
                                                required: true,
                                            },
                                            {
                                                name: "BIRTHDAY[day]",
                                                placeholder: "Birth Day (DD)",
                                                type: "text",
                                                required: true,
                                            },
                                        ]}

                                        className={styles.form}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>                        
                    </Container>
                </div>
            </>
        )

    }
}

export default EmailSignupForm;
