export const getPageBySlug = `query getPageBySlug($slug:String!) {

  pageCollection(limit:1, where: {slug: $slug}) {
    items {
     name
      slug
      heroImage {
        url(transform: {width: 1920, quality: 75, format: WEBP})
      }
      showBlogPosts
      showTheTeam
      theTeamCollection {
        items {
          name
          jobTitle
          photo {
            url(transform: {width: 500, quality: 75, format: WEBP})
          }
          photoFun {
            url(transform: {width: 500, quality: 75, format: WEBP})
          }
          bio
          active
          linkedIn
        }
      }
      showEvents
      eventsCollection {
        items {
            name
            website
            description
            seasons
            heroImage {
                url(transform: {width: 500, quality: 75, format: WEBP})
            }
        }
      }
      showWeddings
      showJobListings
      jobsCollection {
        items {
          jobTitle
          salary
          jobType
          intro
          location
          applicationUrl
        }
      }

      pageBody {
        json
        links {
          entries {
            inline {
                sys {
                  id
                }
              __typename 
                #__typename
                #... on BlogPost {
#                  title
 #                 slug
  #              }
            }
            block {
                sys {
                  id
                }
              __typename 
              ... on Author {
                name
                jobTitle
                photo {
                  url
                }
              }
            }
            
          }
          assets {
              block {
                sys {
                  id
                }
                url(transform: {width: 1920, quality: 75, format: WEBP})sys {
                  id
                }
                title
                width
                height
                description
              }
            }
        }
      }
      sys {
        firstPublishedAt
        publishedAt
      }
    }
  }
  
}
`
